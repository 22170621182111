import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "errors", "backgroundModal", 'tab', 'tabContent']

  connect() {

  }


  openModal() {
    if (this.hasErrorsTarget) { this.errorsTarget.innerHTML = "" };
    this.modalTarget.style.display = "block";
    if (event.target.dataset.tab !== undefined) { this.displayTabContent(event, event.target.dataset.tab) }
  }


  closeModal() {
    if (this.hasInputEmailTarget) {
      this.inputEmailTarget.value = "";
    }
    this.modalTarget.style.display = "none";
  }

  closeBackground(e) {
    if (e.target === this.backgroundModalTarget) { this.closeModal() }
  }

  displayTabs(event) {
    this.tabTargets.forEach(elt => {
      elt.classList.remove('active')
    })
    event.target.classList.add("active")
    this.displayTabContent(event, event.target.dataset.tab)
  }

  displayTabContent(event, tab_selected) {
    let tabContent
    this.tabContentTargets.forEach(elt => {
      elt.classList.remove('hidden')
      elt.classList.add('hidden')
      if (elt.dataset.tab === tab_selected) {
        tabContent = elt
      }
    })
    tabContent.classList.remove('hidden')

  }

}
