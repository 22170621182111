import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modifP"]

  connect() {
    this.modifPTarget.textContent = ""
  }

  modif() {
    this.modifPTarget.textContent = event.target.dataset.text
  }
}
