import { Controller } from "stimulus";
import { initChatroomCable, chatRoomDisplay } from "../channels/chat_room_channel";

export default class extends Controller {


  connect() {
    const chatRoomId = document.getElementById('messages').dataset.channelId;
    chatRoomDisplay(chatRoomId);
  }


}
