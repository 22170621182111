import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";
import { Callbacks } from "jquery";

export default class extends Controller {
  static targets = ['background', 'dropdownToggle', 'fixedDropdown', "dropdownShown"]

  connect() {

  }

  placeFixedDropdown() {
    const height = parseInt(event.target.dataset.height)
    const left = event.target.getBoundingClientRect().left;
    const top = event.target.getBoundingClientRect().top + height;
    this.fixedDropdownTarget.style.left = left + 'px';
    this.fixedDropdownTarget.style.top = top + 'px';
  }

  onScrollSearch() {
    if (this.hasDropdownShownTarget) {
      const height = parseInt(this.dropdownShownTarget.parentElement.parentElement.previousElementSibling.dataset.height)
      const left = this.dropdownShownTarget.parentElement.parentElement.previousElementSibling.getBoundingClientRect().left;
      const top = this.dropdownShownTarget.parentElement.parentElement.previousElementSibling.getBoundingClientRect().top + height;
      this.dropdownShownTarget.parentElement.parentElement.left = left + 'px';
      this.dropdownShownTarget.parentElement.parentElement.style.top = top + 'px';
    }
  }

}
