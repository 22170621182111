import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sidebar", "messagesView", "mobileNav" ]

  connect() {

  }

  hideMessage(){
    this.messagesViewTarget.classList.add('hidden');
    this.sidebarTarget.classList.remove('hidden');
    this.mobileNavTarget.classList.remove('hidden');
  }

  viewMessage(){
    const that = this;
    const element = event.target.classList.contains("sidebar-link") ? event.target : event.target.parentElement
    const messagesContainer = document.querySelector("#messages[data-relationship="+element.id+"]");
    if (messagesContainer) {
      this.sidebarTarget.classList.add('hidden');
      this.mobileNavTarget.classList.add('hidden');
      this.messagesViewTarget.classList.remove('hidden');
    } else {

      document.addEventListener("turbo:before-fetch-response", function (e) {
        that.sidebarTarget.classList.add('hidden');
        that.mobileNavTarget.classList.add('hidden');
        that.messagesViewTarget.classList.remove('hidden');
      }, {once: true})

    }
  }
}
