import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'checkbox','div']

  connect() {
    this.check();
  }

  check() {
    if (this.checkboxTarget.checked === true) {
      this.divTarget.classList.remove('hidden')
    } else {
      this.divTarget.classList.add('hidden')
    }
  }


}
