
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formConnectCompany", "formConnectIndividual", "pPhoneVerificationCompany", "pPhoneVerificationIndividual",
    "expertInfoForm", "companyForm", "individualForm", "expertInfoDiv", "successDiv", 'globalDiv', 'company', 'individual',
    'pUrlVerificationCompany', 'pUrlVerificationIndividual', 'pIbanVerificationCompany', 'pIbanVerificationIndividual']

  connect() {
    if (this.hasSuccessDivTarget) {
      this.successDivTarget.style.display = "none";
      this.toggleForm();
      this.checkRadioButton();
    }
    if (this.hasFormConnectCompanyTarget) {
      const stripe = Stripe(this.formConnectCompanyTarget.dataset.stripeKey);
      const stripe_key = this.formConnectCompanyTarget.dataset.stripeKey;
      const form = this.formConnectCompanyTarget;
      const controller = this;
      form.addEventListener('submit', handleForm);
      async function handleForm(event) {
        event.preventDefault();
        const accountResult = await stripe.createToken('account', {
          business_type: 'company',
          company: {
            name: document.querySelector('.inp-company-name').value,
            address: {
              line1: document.querySelector('.inp-company-street-address1').value,
              city: document.querySelector('.inp-company-city').value,
              state: document.querySelector('.inp-company-state').value,
              postal_code: document.querySelector('.inp-company-zip').value,
            },
            phone: document.querySelector('.inp-person-phone').value,
            tax_id: document.querySelector('.inp-company-tva').value,
            executives_provided: true,
            owners_provided: true,
            directors_provided: true,
          },
          tos_shown_and_accepted: true,
        });

        const dataId = new FormData();
        dataId.append('file', document.querySelector('#id-file').files[0]);
        dataId.append('purpose', 'identity_document');
        const fileResultId = await fetch('https://uploads.stripe.com/v1/files', {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${stripe_key}` },
          body: dataId,
        });
        const fileDataId = await fileResultId.json();
        const dataAddress = new FormData();
        dataAddress.append('file', document.querySelector('#address-file').files[0]);
        dataAddress.append('purpose', 'additional_verification');
        const fileResultAddress = await fetch('https://uploads.stripe.com/v1/files', {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${stripe_key}` },
          body: dataAddress,
        });
        const fileDataAddress = await fileResultAddress.json();
        const personResult = await stripe.createToken('person', {
          person: {
            first_name: document.querySelector('.inp-person-first-name').value,
            last_name: document.querySelector('.inp-person-last-name').value,
            address: {
              line1: document.querySelector('.inp-person-street-address1').value,
              city: document.querySelector('.inp-person-city').value,
              state: document.querySelector('.inp-person-state').value,
              postal_code: document.querySelector('.inp-person-zip').value,
            },
            dob: {
              day: document.querySelector('.inp-person-day').value,
              month: document.querySelector('.inp-person-month').value,
              year: document.querySelector('.inp-person-year').value,
            },
            email: document.querySelector('.inp-person-email').value,
            phone: document.querySelector('.inp-person-phone').value,
            relationship: {
              executive: true,
              director: true,
              owner: true,
              representative: true,
              title: 'pdg',
            },
            verification: {
              document: {
                front: fileDataId.id,
              },
              additional_document: {
                front: fileDataAddress.id,
              },
            },
          },
        });
        if (accountResult.token && personResult.token) {
          document.querySelector('#token-account-company').value = accountResult.token.id;
          document.querySelector('#token-person-company').value = personResult.token.id;
          document.querySelector('#url-company').value = document.querySelector(".inp-company-url").value;
          document.querySelector('#description-company').value = document.querySelector(".inp-company-description").value;
          document.querySelector('#iban-company').value = document.querySelector(".inp-iban").value;
          document.querySelector('#siren-company').value = document.querySelector(".inp-company-siren").value;
          document.querySelector('#tva-company').value = document.querySelector(".inp-company-tva").value;
          controller.toggleSuccess();
          form.submit();
        }
      }
    }
    if (this.hasFormConnectIndividualTarget) {
      const stripe = Stripe(this.formConnectIndividualTarget.dataset.stripeKey);
      const stripe_key = this.formConnectIndividualTarget.dataset.stripeKey;
      const form = this.formConnectIndividualTarget;
      const controller = this;
      form.addEventListener('submit', handleForm);
      async function handleForm(event) {
        event.preventDefault();
        const accountResult = await stripe.createToken('account', {
          business_type: 'individual',
          tos_shown_and_accepted: true,
        });
        const dataId = new FormData();
        dataId.append('file', document.querySelector('#id-file-individual').files[0]);
        dataId.append('purpose', 'identity_document');
        const fileResultId = await fetch('https://uploads.stripe.com/v1/files', {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${stripe_key}` },
          body: dataId,
        });
        const fileDataId = await fileResultId.json();
        const dataAddress = new FormData();
        dataAddress.append('file', document.querySelector('#address-file-individual').files[0]);
        dataAddress.append('purpose', 'additional_verification');
        const fileResultAddress = await fetch('https://uploads.stripe.com/v1/files', {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${stripe_key}` },
          body: dataAddress,
        });
        const fileDataAddress = await fileResultAddress.json();
        const personResult = await stripe.createToken('person', {
          person: {
            first_name: document.querySelector('.inp-person-first-name-individual').value,
            last_name: document.querySelector('.inp-person-last-name-individual').value,
            address: {
              line1: document.querySelector('.inp-person-street-address1-individual').value,
              city: document.querySelector('.inp-person-city-individual').value,
              state: document.querySelector('.inp-person-state-individual').value,
              postal_code: document.querySelector('.inp-person-zip-individual').value,
            },
            dob: {
              day: document.querySelector('.inp-person-day-individual').value,
              month: document.querySelector('.inp-person-month-individual').value,
              year: document.querySelector('.inp-person-year-individual').value,
            },
            email: document.querySelector('.inp-person-email-individual').value,
            phone: document.querySelector('.inp-person-phone-individual').value,
            relationship: {
              executive: true,
              director: true,
              owner: true,
              representative: true,
              title: 'pdg',
            },
            verification: {
              document: {
                front: fileDataId.id,
              },
              additional_document: {
                front: fileDataAddress.id,
              },
            },
          },
        });
        if (accountResult.token && personResult.token) {
          document.querySelector('#token-account-individual').value = accountResult.token.id;
          document.querySelector('#token-person-individual').value = personResult.token.id;
          document.querySelector('#url-individual').value = document.querySelector(".inp-individual-url").value;
          document.querySelector('#description-individual').value = document.querySelector(".inp-individual-description").value;
          document.querySelector('#iban-individual').value = document.querySelector(".inp-individual-iban").value;
          controller.toggleSuccess();
          form.submit();
        }
      }
    }
  }

  phoneVerificationCompany() {
    if (event.target.value.includes("+33")) {
      this.pPhoneVerificationCompanyTarget.style.color = "#505253"
    } else {
      this.pPhoneVerificationCompanyTarget.style.color = "red"
    }
  }

  phoneVerificationIndividual() {
    if (event.target.value.includes("+33")) {
      this.pPhoneVerificationIndividualTarget.style.color = "#505253"
    } else {
      this.pPhoneVerificationIndividualTarget.style.color = "red"
    }
  }

  urlVerificationCompany() {
    if (event.target.value.includes("http")) {
      this.pUrlVerificationCompanyTarget.style.color = "#505253"
    } else {
      this.pUrlVerificationCompanyTarget.style.color = "red"
    }
  }

  urlVerificationIndividual() {
    if (event.target.value.includes("http")) {
      this.pUrlVerificationIndividualTarget.style.color = "#505253"
    } else {
      this.pUrlVerificationIndividualTarget.style.color = "red"
    }
  }

  ibanVerificationIndividual() {
    if (event.target.value.includes("FR") && event.target.value.length === 27) {
      this.pIbanVerificationIndividualTarget.style.color = "#505253"
    } else {
      this.pIbanVerificationIndividualTarget.style.color = "red"
    }
  }

  ibanVerificationCompany() {
    if (event.target.value.includes("FR") && event.target.value.length === 27 ) {
      this.pIbanVerificationCompanyTarget.style.color = "#505253"
    } else {
      this.pIbanVerificationCompanyTarget.style.color = "red"
    }
  }


  submitExpertInfo() {

    if (event.target.dataset.side === 'true') {
      document.getElementById('expert_info_is_individual').value = true
      document.getElementsByName('expert_info[is_individual]').value = true
    } else {
      document.getElementById('expert_info_is_individual').value = false
      document.getElementsByName('expert_info[is_individual]').value = false
    }

    this.expertInfoFormTarget.submit();
    this.changeDataIndividual(document.getElementById('expert_info_is_individual').value)
    this.toggleForm();
  }

  changeDataIndividual(value) {
    this.expertInfoDivTarget.dataset.isIndividual = value
  }

  toggleForm() {
    if (this.expertInfoDivTarget.dataset.isIndividual === "true") {
      this.individualFormTarget.style.display = "block";
      this.companyFormTarget.style.display = "none";
    } else if (this.expertInfoDivTarget.dataset.isIndividual === "false") {
      this.individualFormTarget.style.display = "none";
      this.companyFormTarget.style.display = "block";
    } else {
      this.individualFormTarget.style.display = "none";
      this.companyFormTarget.style.display = "none";
    }
  }

  checkRadioButton() {
    if (this.expertInfoDivTarget.dataset.isIndividual === "true") {
      this.companyTarget.checked = false
      this.individualTarget.checked = true
    } else if (this.expertInfoDivTarget.dataset.isIndividual === "false") {
      this.companyTarget.checked = true
      this.individualTarget.checked = false
    } else {
      this.companyTarget.checked = false
      this.individualTarget.checked = false
    }

  }

  toggleSuccess() {
    this.globalDivTarget.style.display = "none";
    this.successDivTarget.style.display = "block";
  }


}
