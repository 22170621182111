import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["select_form_client", "relationship_id", "create_form_client",
    "non_user_first_name", "non_user_last_name", "non_user_email", "non_user_phone",
    "createCourseMembershipButton", "course_session_id",
    "modal", "backgroundModal", "errors", 'table', 'header', 'titleNoBooking',
    "modalModifSession", "backgroundModalModifSession", "new_coursesession_id", 'updateCourseMembershipButton', 'row',
    "clientInfos", "paymentCell", "amountPayment", "cmPayment",'send_mail']

  connect() {
    console.log('cou');
  }

  createCourseMembership() {
    const course_session_id = this.course_session_idTarget.value;
    const relationship_id = this.relationship_idTarget.value;
    const send_mail = this.send_mailTarget.checked
    if (relationship_id !== "") {
      var createCourseMembershipParams = {
        send_mail: send_mail,
        course_membership: { relationship_id: relationship_id, course_session_id: course_session_id }
      }
    } else {
      const non_user_first_name = this.non_user_first_nameTarget.value;
      const non_user_last_name = this.non_user_last_nameTarget.value;
      const non_user_email = this.non_user_emailTarget.value;
      const non_user_phone = this.non_user_phoneTarget.value;
      var createCourseMembershipParams = {
        send_mail: send_mail,
        course_membership: { relationship_id: relationship_id, course_session_id: course_session_id },
        non_user: { first_name: non_user_first_name, last_name: non_user_last_name, email: non_user_email, phone: non_user_phone }
      }
    }

    fetch("/course_memberships/create_course_membership_and_or_user_in_session", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify(createCourseMembershipParams)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.inserted_item) {
          if (this.headerTarget.classList.contains('hidden')) {
            this.headerTarget.classList.remove('hidden')
            this.titleNoBookingTarget.classList.add('hidden')
          }
          this.tableTarget.insertAdjacentHTML('beforeend', data.inserted_item);
        }
      });

    this.closeModal();
  }


  selectClientCourseMembeship() {
    this.disabledCreatedButton()
  }

  toggleNonUserForm() {
    this.create_form_clientTarget.classList.toggle('hidden')
  }

  disabledCreatedButton() {
    if ((this.relationship_idTarget.value != "") || (this.non_user_first_nameTarget.value != "" && this.non_user_last_nameTarget.value != "")) {
      this.createCourseMembershipButtonTarget.disabled = false;
    }
  }

  openModal() {
    if (this.hasErrorsTarget) { this.errorsTarget.innerHTML = "" };
    this.modalTarget.style.display = "block";
  }

  closeModal() {
    this.relationship_idTarget.selectedIndex = 0;
    this.non_user_first_nameTarget.value = ""
    this.non_user_last_nameTarget.value = ""
    this.non_user_phoneTarget.value = ""
    this.non_user_emailTarget.value = ""
    this.modalTarget.style.display = "none";
    this.createCourseMembershipButtonTarget.disabled = true;
    if (!this.create_form_clientTarget.classList.contains("hidden")) {
      this.create_form_clientTarget.classList.add("hidden")
    }
  }

  closeBackground(e) {
    if (e.target === this.backgroundModalTarget) { this.closeModal() }
  }

  openModalModifSession() {
    this.modalModifSessionTarget.style.display = "block";
    this.updateCourseMembershipButtonTarget.dataset.course_membershipId = event.target.dataset.course_membershipId
  }

  closeModalModifSession() {
    this.modalModifSessionTarget.style.display = "none";
  }

  closeBackgroundModifSession(e) {
    if (e.target === this.backgroundModalModifSessionTarget) { this.closeModalModifSession() }
  }

  updateCourseMembership() {
    const new_course_session_id = this.new_coursesession_idTarget.value;
    const course_membership_id = event.target.dataset.course_membershipId;
    if (this.new_coursesession_idTarget.value === '') {
      this.closeModalModifSession();
    } else {
      console.log('coucou')
      console.log(course_membership_id)
      var updateCourseMembership = { course_membership: { id: course_membership_id, course_session_id: new_course_session_id }}
      fetch("/course_memberships/update_course_membership_in_session", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(updateCourseMembership)
      })
        .then(response => response.json())
        .then((data) => {
          this.rowTargets.forEach(element => {
            if (element.dataset.course_membershipId == data['id']) {
              element.classList.add('hidden')
            }
          });
          this.closeModalModifSession();
        })
    }
  }

  showClientInfos() {
    this.clientInfosTarget.style.transform = ("");
  }

  hideClientInfos() {
    this.clientInfosTarget.style.transform = ("translateX(100%) translateZ(0px)");
  }

  updatePayment() {
    const amountToAdd = this.amountPaymentTarget.value
    if (this.hasCmPaymentTarget) {
      this.paymentCellTargets.forEach(element => {
        if (element.dataset.course_membershipId == this.cmPaymentTarget.value ) {
          const new_amount = parseInt(element.dataset.amount) + parseInt(amountToAdd)
          element.innerHTML = `${new_amount}€ / ${element.dataset.total}€ `
          element.dataset.amount = new_amount
          }
        });
    }

  }
}





