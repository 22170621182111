import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tooltip", 'button', "arrow"]
  static values = { placement: String }

  connect() {
    this.buttonTarget.classList.add('relative')
    if (this.placementValue === 'right') {
      this.tooltipTarget.classList.add('right')
      this.arrowTarget.classList.add('triangle-right')
    }
  }

  mouseOver() {
    this.tooltipTarget.classList.toggle('invisible')
    this.tooltipTarget.classList.toggle('visible')
  }

  mouseOut() {
    this.tooltipTarget.classList.toggle('invisible')
    this.tooltipTarget.classList.toggle('visible')
  }
}
