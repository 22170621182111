import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.element.remove()
  }
}
