import Flatpickr from "stimulus-flatpickr";


// import a theme (could be in your main CSS entry too...)
 import "flatpickr/dist/themes/light.css";

// import the translation files and create a translation mapping
import { French } from "flatpickr/dist/l10n/fr.js";
import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  locales = {
    fr: French,
    en: english
  };

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locale,
      showMonths: 1,
      enableTime: true,
      altInput: true,
      altFormat: "d/m/Y à H\\hi",
      dateFormat: 'Z',
      minuteIncrement: 30,
    };
    super.connect();
  }

  close(selectedDates, dateStr, instance) {
    const startDate = document.getElementById('start_on').dataset.start
    const endDate = document.getElementById('end_on').dataset.end
    const dateString = instance.altInput.value;
    const dateArray = dateString.split(' ');
    const hour = parseInt(dateArray[2].split('h')[0]);
    const minute = parseInt(dateArray[2].split('h')[1]);
    const diff = new Date(endDate) - new Date(startDate)
    if (diff % 3600000 === 0 ) {
      const hour_to_add = diff / 3600000
      const newHour = hour + hour_to_add;
      const newEnd = dateString.substring(0, 13) +
        newHour +
        dateString.substring(15, dateString.length);
      document.getElementById('end_on').innerHTML = `<span class="font-bold">Au :</span> ${newEnd}`;
    } else {
      const time_to_add  = diff / 3600000
      const hour_to_add = Math.floor(time_to_add)
      let newHour = hour + hour_to_add;
      const minute_to_add = (time_to_add - Math.floor(time_to_add)) * 60
      let newMinute = minute + minute_to_add ;
      if (newMinute >= 60) {
        newHour = newHour + 1;
        newMinute = newMinute - 60
        if (newMinute < 10) {
          newMinute = '0' + newMinute
        }
      }
      const newEnd = dateString.substring(0, 13) +
          newHour + 'h' + newMinute;
      document.getElementById('end_on').innerHTML = `<span class="font-bold">Au :</span> ${newEnd}`;
    }
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return "";
    }
  }
}
