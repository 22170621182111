import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form",
  'titleInput', 'titleTemplateInput', 'bodyTemplateInput', 'listInput',
  'titleError', 'titleTemplateError', 'bodyTemplateError', 'listError',
  'dropdownShown', 'relativeButton', 'allButton', 'tagButton']

  connect() {
    if (this.allButtonTarget.dataset.selected === 'true') {
      this.toggleClass('all')
    }
    if (this.relativeButtonTarget.dataset.selected === 'true') {
      this.toggleClass('tags')
    }
  }

  submit() {
    const form = this.formTarget;
    this.titleErrorTarget.textContent = ''
    this.titleTemplateErrorTarget.textContent = ''
    this.bodyTemplateErrorTarget.textContent = ''
    this.listErrorTarget.textContent = ''
    let isComplete = true;
    if (this.titleInputTarget.value === "") {
      this.titleErrorTarget.textContent = 'Doit être rempli'
      isComplete = false
    }
    if (this.titleTemplateInputTarget.value === "") {
      isComplete = false
      this.titleTemplateErrorTarget.textContent = 'Doit être rempli'
    }
    if (this.bodyTemplateInputTarget.value === "") {
      this.bodyTemplateErrorTarget.textContent = 'Doit être rempli'
      isComplete = false
    }
    if (this.listInputTarget.innerHTML === 'Aucun contact ajouté') {
      this.listErrorTarget.textContent = 'Ne peut pas être vide'
      isComplete = false
    }
    if (isComplete) {
      form.submit()
    }
  }

  onScroll() {
    if (this.hasDropdownShownTarget) {
      const left = this.relativeButtonTarget.getBoundingClientRect().left;
      const top = this.relativeButtonTarget.getBoundingClientRect().top + 40;
      const dropdown = this.dropdownShownTarget
      dropdown.style.left = left + 'px';
      dropdown.style.top = top + 'px';
    }
  }

  switchActive() {
    if (event.target.dataset.button === "allContacts") {
      this.toggleClass('all')
    } else if ( event.target.dataset.button === 'tagsButton') {
      this.toggleClass('tags')
    }
  }

  toggleClass(str) {
    if (str === 'all') {
      this.allButtonTarget.classList.remove('bg-white')
      this.allButtonTarget.classList.remove('border')
      this.allButtonTarget.classList.add('bg-blue-600')
      this.allButtonTarget.classList.add('text-white')
      this.relativeButtonTarget.classList.remove('bg-white')
      this.relativeButtonTarget.classList.remove('text-white')
      this.relativeButtonTarget.classList.remove('border')
      this.relativeButtonTarget.classList.remove('bg-blue-600')
      this.relativeButtonTarget.classList.add('bg-white')
      this.relativeButtonTarget.classList.add('border')
    } else {
      this.relativeButtonTarget.classList.remove('bg-white')
      this.relativeButtonTarget.classList.remove('border')
      this.relativeButtonTarget.classList.add('bg-blue-600')
      this.relativeButtonTarget.classList.add('text-white')
      this.allButtonTarget.classList.remove('bg-white')
      this.allButtonTarget.classList.remove('text-white')
      this.allButtonTarget.classList.remove('border')
      this.allButtonTarget.classList.remove('bg-blue-600')
      this.allButtonTarget.classList.add('bg-white')
      this.allButtonTarget.classList.add('border')
    }
  }

  addVariable() {
    const input = this.bodyTemplateInputTarget
    if (event.target.dataset.variable === "prenom") {
      input.editor.insertString("{{ first_name }}")
      this.saveAutomatically()
    }
    else {
      input.editor.insertString("{{ last_name }}")
      this.saveAutomatically()
    }
  }

  saveAutomatically() {
    const form = this.formTarget
    const urlToken = form.dataset.urlToken
    if (this.titleInputTarget.value !== "" && this.titleTemplateInputTarget.value !== "" && this.bodyTemplateInputTarget.value !== "" && this.listInputTarget.innerHTML !== 'Aucun contact ajouté') {
      console.log('coucou');
      fetch(`/mailings/${urlToken}/update_automatically`, {
        method: 'PATCH',
        headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
        body: new FormData(form)
      })
        .then(response => response.json())
        .then((data) => {
          console.log(data);
        });
    }
  }
}
