import { Controller } from "stimulus"
import { fetchWithToken } from "../utils/fetch_with_token";
export default class extends Controller {
  //  gère les validations en js
  //  en target le form, les input et les p d'alerte au dessus des input
  //  remplacer le button submit du devise form par un autre boutton et lui appliquer l'action submit
  //  exemple associé a ce controller new_registration.html.erb
  static targets = ["form", "inputFirstName", "inputLastName", "inputPhone", "inputEmail",
    "paragrahFirstName", "paragrahLastName", "paragrahPhone", "paragrahEmail",
    "paragrahAddress", "inputAddress", "paragrahZipCode", "inputZipCode", "paragrahCity", "inputCity",
    "paragrahCountry", "inputCountry"  ]

  connect() {

  }

  submit(event) {
    event.preventDefault;
    const is_clicked_already = event.target.dataset.clicked
    if (this.hasInputFirstNameTarget) { this.paragrahFirstNameTarget.innerText = "" }
    if (this.hasInputLastNameTarget) { this.paragrahLastNameTarget.innerText = ""}
    if (this.hasInputPhoneTarget) { this.paragrahPhoneTarget.innerText = "" }
    if (this.hasInputEmailTarget) { this.paragrahEmailTarget.innerText = "" }
    if (this.hasInputAddressTarget) { this.paragrahAddressTarget.innerText = "" }
    if (this.hasInputZipCodeTarget) { this.paragrahZipCodeTarget.innerText = "" }
    if (this.hasInputCityTarget) { this.paragrahCityTarget.innerText = "" }
    if (this.hasInputCounrtyTarget) { this.paragrahCounrtyTarget.innerText = "" }
    this.paragrahEmailTarget.innerText = ""
    let is_validated = true
    if (this.hasInputFirstNameTarget && this.inputFirstNameTarget.value === "") {
      this.paragrahFirstNameTarget.innerText = "Le champ prénom doit être rempli"
      is_validated = false;
    };
    if (this.hasInputLastNameTarget && this.inputLastNameTarget.value === "") {
      this.paragrahLastNameTarget.innerText = "Le champ nom doit être rempli"
      is_validated = false;
    };
    // if (this.hasInputPhoneTarget && (this.inputPhoneTarget.value === "" || !/^0(6|7)\d{8}$/.test(this.inputPhoneTarget.value))) {
    //   this.paragrahPhoneTarget.innerText = "Le champ téléphone doit être rempli au format 06/07/12345678"
    //   is_validated = false;
    // };
    if (this.hasInputPhoneTarget && (this.inputPhoneTarget.value === "" )) {
      this.paragrahPhoneTarget.innerText = "Le champ téléphone doit être rempli"
      is_validated = false;
    };
    if (this.hasInputEmailTarget && (this.inputEmailTarget.value === "" || !this.inputEmailTarget.value.includes("@"))) {
      this.paragrahEmailTarget.innerText = "L'email est invalide ou vide"
      is_validated = false;
    };

    if (this.hasInputAddressTarget && (this.inputAddressTarget.value === "")) {
      this.paragrahAddressTarget.innerText = "Le champ adresse doit être rempli"
      is_validated = false;
    };
    if (this.hasInputZipCodeTarget && (this.inputZipCodeTarget.value === "")) {
      this.paragrahZipCodeTarget.innerText = "Le champ code postal doit être rempli"
      is_validated = false;
    };
    if (this.hasInputCityTarget && (this.inputCityTarget.value === "")) {
      this.paragrahCityTarget.innerText = "Le champ villle doit être rempli"
      is_validated = false;
    };
    if (this.hasInputCountryTarget && (this.inputCountryTarget.value === "")) {
      this.paragrahCountryTarget.innerText = "Le champ pays doit être rempli"
      is_validated = false;
    };

    if (is_validated && is_clicked_already === "false") {
      event.target.dataset.clicked = 'true'
      this.formTarget.submit();
    }
  }


}
