import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "input", "inputToggle"]

  connect() {
    if (this.inputToggleTarget.dataset.boolean == "true") {
      this.inputToggleTarget.checked = true
    } else {
      this.inputToggleTarget.checked = false
    }
    this.inputTarget.value = this.inputToggleTarget.dataset.boolean
  }

  submit(event) {
    event.stopPropagation();
    if (event.target.checked) {
      this.inputTarget.value = true
    } else {
      this.inputTarget.value = false
    }
    this.formTarget.submit(this.inputTarget.value );
  }
}
