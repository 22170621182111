import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["sessions", "form", "noSessions", "error", 'flatStart', 'flatEnd', 'startOnInput']
  static values = { position: String }

  connect() {
  }



  changeStartOnInput() {
    const endFp = this.flatEndTarget._flatpickr;
    endFp.config.minDate = event.target.value
    if (endFp.selectedDates[0] === undefined || endFp.selectedDates[0] < event.target.value) {
      endFp.setDate(event.target.value)
      document.getElementById('course_session_end_on').value = event.target.value
      this.changeTimeInputEnd()
    }
  }

  changeTimeInputStart() {
    const date_in_array = document.getElementById('course_session_start_on').value.split(' ')
    date_in_array[4] = `${document.getElementById('start_at_hour').value}:${document.getElementById('start_at_minute').value}:00`
    document.getElementById('course_session_start_on').value = date_in_array.join(' ')
  }

  changeTimeInputEnd() {
    const date_in_array = document.getElementById('course_session_end_on').value.split(' ')
    date_in_array[4] = `${document.getElementById('end_at_hour').value}:${document.getElementById('end_at_minute').value}:00`
    document.getElementById('course_session_end_on').value = date_in_array.join(' ')
  }
}

