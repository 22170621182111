import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "errors", "list", "form", "inputEmail","backgroundModal"]

  connect() {

  }

  pinked() {
    const active_link = document.querySelector(".sidebar > .active");
    const element = event.target.classList.contains("sidebar-link") ? event.target : event.target.parentElement

    if (active_link != element){
      element.classList.add('active');
      active_link.classList.remove('active');
    }
  }

  openModal() {
    if( this.hasErrorsTarget) {this.errorsTarget.innerHTML = ""};
    this.modalTarget.style.display = "block";
  }


  closeModal() {
    if (this.hasInputEmailTarget) {
      this.inputEmailTarget.value = "";
    }
    this.modalTarget.style.display = "none";
  }

  closeBackground(e) {
    if (e.target === this.backgroundModalTarget) { this.closeModal() }
  }

}
