import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['pEmptyList', 'list']

  connect() {
  }

  submit() {
    if (this.listTarget.textContent.trim() === "Vous n'avez pas de destinataires") {
      event.preventDefault()
      event.stopImmediatePropagation()
      this.pEmptyListTarget.innerHTML = "Vous n'avez pas de destinataires"
    }
  }
}
