import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["choiceDiv",
    "modal", "backgroundModal"]

  connect() {
  }

  showChoice() {
    if (event.target.value ==='multiple_choice') {
      this.choiceDivTarget.classList.remove('hidden')
    } else {
      this.choiceDivTarget.classList.add('hidden')
    }

  }
  addChoice() {
    const numberChoice = parseInt(event.target.dataset.numberChoice) + 1
    event.target.dataset.numberChoice = numberChoice
    // creer la div
    const s = `
    <div class="mb-4 string required new_question_choice_${numberChoice}">
      <label class="block string required text-sm font-medium text-grey-700" for="new_question_choice_${numberChoice}">Choix ${numberChoice} </label>
      <input class="appearance-none border border-grey-400 rounded-md w-full py-2 px-3 bg-white focus:outline-none focus:ring-0 focus:border-blue-700 text-grey-700 leading-6 transition-colors duration-200 ease-in-out string required" type="text" name="new_question[choice_${numberChoice}]" id="new_question_choice_${numberChoice}">
    </div>
    `
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = s;
    event.target.insertAdjacentElement('beforebegin', htmlObject)
  }

  openModal() {
    this.modalTarget.style.display = "block";
  }

  closeModal() {
    this.modalTarget.style.display = "none";
  }

  closeBackground(e) {
    if (e.target === this.backgroundModalTarget) { this.closeModal() }
  }
}


