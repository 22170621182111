import consumer from "./consumer";
import { fetchWithToken } from "../utils/fetch_with_token";
import { mobileCheck } from "../utils/mobile_check";


const scrollLastMessageIntoView = () => {
  const messages = document.querySelectorAll('.message-container');
  const lastMessage = messages[messages.length - 1];

  if (lastMessage !== undefined) {
    lastMessage.scrollIntoView();
  }
}


const seenMessages = (channelId) => {

  fetchWithToken("/relationships/"+channelId+"/seen_messages", {
    method: 'PATCH',
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  }).then(response => response.json())
    .then((data) => {
      if (mobileCheck()) {

        document.querySelector(`#relationship-${data.relationship_id} > .notification-container > .notification`).classList.remove("active")
      } else {
        document.querySelector(".relationship_navbar > .navbar-link > .notification-container > .notification").classList.remove("active")
        if (document.querySelector(`#relationship-${data.relationship_id}`)) {
          document.querySelector(`#relationship-${data.relationship_id} > .notification-container > .notification`).classList.remove("active")
        }
      }
     if (data.current_user_unread_messages_count == 0) {
      if (mobileCheck()) {
        document.querySelector(".navbar-link> .notification-container > .all-notification").classList.remove("active");
       } else {
         document.querySelector(".sidenav-link > .notification-container > .all-notification").classList.remove("active");
       }
     }
    });
}

const initChatroomCable = () => {
  if (document.getElementById('user-id')) {
    const currentUserId = document.getElementById('user-id').dataset.user_id;
    const chatRooms = document.querySelectorAll(".chat-room-subscription");
    if (chatRooms.length > 0) {
      Array.prototype.forEach.call(chatRooms, function (element) {
        const chatRoomId = element.dataset.relationship_id;
        console.log("subscribed to " + chatRoomId);
        consumer.subscriptions.create(
          { channel: "ChatRoomsChannel", chat_room_id: chatRoomId }, {
          received(data) {
            console.log(chatRoomId);
            if (data.current_user_id !== parseInt(currentUserId)) {
              const messagesContainer = document.querySelector("#messages[data-channel-id="+CSS.escape(chatRoomId)+"]");
              console.log(messagesContainer);
              if (messagesContainer) {
                messagesContainer.insertAdjacentHTML('beforeend', data.body);
                scrollLastMessageIntoView();
              } else {
                // notif(chatRoomId);
              }
            }
          },
        })
      });
    }
  }
}

const chatRoomDisplay = (chatRoomId) => {
  scrollLastMessageIntoView();
  seenMessages(chatRoomId);
}

export { chatRoomDisplay, initChatroomCable, scrollLastMessageIntoView };

