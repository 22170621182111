import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['individual', 'collective', 'program', 'input']

  connect() {
  }

  changeInput() {
    if (event.target.dataset.type === 'individual' || event.target.parentElement.dataset.type === 'individual') {
      this.inputTarget.value = 'individual'
      this.changeFocus('individual');
    } else if (event.target.dataset.type === 'collective' || event.target.parentElement.dataset.type === 'collective') {
      this.inputTarget.value = 'collective'
      this.changeFocus('collective');
    } else if (event.target.dataset.type === 'program' || event.target.parentElement.dataset.type === 'program') {
      this.inputTarget.value = 'program'
      this.changeFocus('program');
    }
  }


  changeFocus(str) {
    if (str ==='individual') {
      this.changeClass(this.individualTarget, this.collectiveTarget, this.programTarget)
    } else if (str === 'collective') {
      this.changeClass(this.collectiveTarget, this.individualTarget, this.programTarget)
    } else if (str === 'program') {
      this.changeClass(this.programTarget, this.individualTarget, this.collectiveTarget)
    }
  }

  changeClass(target, untarget1, untarget2) {
    target.classList.add('border-primary-blue')
    target.classList.remove('border-grey-400')
    untarget1.classList.remove('border-primary-blue')
    untarget1.classList.remove('border-grey-400')
    untarget1.classList.add('border-grey-400')
    untarget2.classList.remove('border-primary-blue')
    untarget2.classList.remove('border-grey-400')
    untarget2.classList.add('border-grey-400')
  }
}
