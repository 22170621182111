import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dropdownToggle"]

  connect() {
  }

  dropdownToggle() {
    this.dropdownToggleTarget.classList.toggle("hidden");
  }
}
