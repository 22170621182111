import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['answerForm', 'surveyForm']

  connect() {
  }

  submit() {
    let is_validated = true
    const is_clicked_already = event.target.dataset.clicked
    let answers = {}
    this.answerFormTargets.forEach(form => {
      const input = form.querySelector(`[data-question="${form.dataset.question}"]`)
      const errorParagraph = form.getElementsByClassName('error-paragraph')[0]
      errorParagraph.innerText = ""
      if (input.value === "") {
        errorParagraph.innerText = "Doit être rempli"
        is_validated = false;
      }
      if (input.type === 'checkbox' && input.checked === false && input.dataset.format == 'boolean' ) {
        errorParagraph.innerText = "Doit être accepté"
        is_validated = false;
      }
      if (input.dataset.answer !== undefined) {
        answers[`${form.dataset.question}/${input.dataset.answer}`] = input.checked
      } else {
        answers[form.dataset.question] = input.value
      }
    });
    let surveys = {}
    const surveyForms = this.surveyFormTargets
    if (this.hasSurveyFormTarget) {
      surveyForms.forEach(form => {
        const input = form.querySelector(`[data-type="${form.dataset.type}"]`)
        const errorParagraph = form.getElementsByClassName('error-paragraph')[0]
        errorParagraph.innerText = ""
        if (input.value === "") {
          errorParagraph.innerText = "Doit être rempli"
          is_validated = false;
        }
        else {
          surveys[form.dataset.type] = input.value
        }
      });
    }
    if (is_validated && is_clicked_already === "false") {
      event.target.dataset.clicked = true
      fetch(`/forms/${event.target.dataset.formUrlToken}/surveys`, {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
        body: JSON.stringify({ answers: answers, surveys: surveys })
      })
        .then(response => response.json())
        .then((data) => {
          window.location.href = `${window.location.origin}/surveys/${data["survey_url_token"]}/confirmation`
        });
    }
  }

}
