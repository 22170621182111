import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['answerForm']
  static values = {
    survey: String
  }

  connect() {

  }

  submit() {
    let is_validated = true
    let answers = {}
    const is_clicked_already = event.target.dataset.clicked
    const survey = this.surveyValue
    this.answerFormTargets.forEach(form => {
      const input = form.querySelector(`[data-question="${form.dataset.question}"]`)
      const errorParagraph = form.getElementsByClassName('error-paragraph')[0]
      errorParagraph.innerText = ""
      if (input.value === "") {
        errorParagraph.innerText = "Doit être rempli"
        is_validated = false;
      }
      if (input.type === 'checkbox' && input.checked === false && input.dataset.format == 'boolean') {
        errorParagraph.innerText = "Doit être accepté"
        is_validated = false;
      }
      if (input.dataset.answer !== undefined) {
        answers[`${form.dataset.question}/${input.dataset.answer}`] = input.checked
      } else {
        answers[form.dataset.question] = input.value
      }
    });
    if (is_validated && is_clicked_already === "false") {
      event.target.dataset.clicked = true
      fetch(`/surveys/${survey}/booking_survey`, {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
        body: JSON.stringify({ answers: answers })
      })
        .then(response => response.json())
        .then((data) => {
          if (parseInt(data["price"])  > 0 ) {
            window.location.href = `${window.location.origin}/payments/new?booking_url_token=${data["booking_url_token"]}`
          } else {
            window.location.href = `${window.location.origin}/bookings/${data["booking_url_token"]}/confirmation`
          }
        });
    }
  }

}
