import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "inputToggle"]

  connect() {
    if (this.hasInputToggleTarget && this.inputToggleTarget.dataset.boolean == "true") {
      this.inputToggleTarget.checked = true
      this.inputTarget.value = 'active'
    } else if (this.hasInputToggleTarget) {
      this.inputToggleTarget.checked = false
      this.inputTarget.value = 'archived'
    }
  }

  switch(event) {
    event.stopPropagation();
    if (event.target.checked) {
      this.inputTarget.value = 'active'
    } else {
      this.inputTarget.value = 'archived'
    }
  }
}
