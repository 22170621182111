import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["imageDisplay", "imageInput"]

  connect() {

  }

  selectPhoto() {
    var input = this.imageInputTarget
    var output = this.imageDisplayTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}
