import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["edit", 'show']

  connect() {

  }

  toggleEdit() {
    this.editTarget.classList.toggle('hidden')
    this.showTarget.classList.toggle('hidden')
  }
}
