import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formUser", "fullName", "title", "content", 'firstNameInput', 'lastNameInput',  'titleInput', 'contentInput',
    "phoneInput", "errorPerso", 'pPhoneVerification', "div1", "div2"]

  connect() {
    if (this.hasDiv2Target) {
      this.div2Target.style.display = "none"
    }
  }

  nextPage() {
    const controller = this;
    if (event.target.dataset.page === "page_user") {
      let isValid = false;
      if (/0(6|7)\d{8}$/.test(controller.phoneInputTarget.value)) {isValid = true }
      if (controller.firstNameInputTarget.value != "" && controller.lastNameInputTarget.value != "" && isValid) {
        controller.formUserTarget.submit();
      } else {
        controller.errorPersoTarget.style.display = 'block'
      }
    }
  }

  changeInput() {
    const firstName = this.firstNameInputTarget.value;
    const lastName = this.lastNameInputTarget.value;
    this.fullNameTarget.textContent = `${firstName} ${lastName}`
  }

  changeInputTitle() {
    const titleInput =  this.titleInputTarget.value;
    this.titleTarget.textContent = `${titleInput}`
  }
  changeInputContent() {
    const contentInput = this.contentInputTarget.value;
    this.contentTarget.textContent = `${contentInput}`
  }

  phoneVerification() {
    if (/0(6|7)\d{8}$/.test(event.target.value.toString())) {
      this.pPhoneVerificationTarget.style.color = "#505253"
    } else {
      this.pPhoneVerificationTarget.style.color = "red"
    }
  }

  isFilled(inputs_in_collection) {
    const inputs_in_array = Array.from(inputs_in_collection);
    let is_filled = true
    inputs_in_array.some(input => {
      if (input.value === "" && input.name != "expert_info[url]") {
        is_filled = false
        return is_filled
      }
    })
    return is_filled;
  }

  switchDiv() {
    this.div1Target.style.display = "none"
    this.div2Target.style.display = "flex"
  }
}
