import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["documentInput", "courseInput", 'document', 'backgroundModal', 'fileInput']

  connect() {
  }

  addValueInForm() {
    this.courseInputTarget.value = event.target.dataset.courseId
    this.documentInputTarget.value = event.target.dataset.documentId
    this.documentTargets.forEach(element => {
      if (element.classList.contains("font-bold")) {
        element.classList.remove('font-bold')
      }
    });
    event.target.classList.add('font-bold')
  }

  closeModal() {
    this.documentTargets.forEach(element => {
      if (element.classList.contains("font-bold")) {
        element.classList.remove('font-bold')
      }
    });
    this.courseInputTarget.value = ""
    this.documentInputTarget.value = ""
    this.fileInputTarget.value = null
  }

  closeBackground() {
    if (event.target === this.backgroundModalTarget) { this.closeModal() }
  }
}
