import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['document', 'backgroundModal', 'fileInput', "documentInput"]

  connect() {
  }

  addValueInForm() {
    this.documentInputTarget.value = event.target.value
  }

  closeModal() {
    this.documentTargets.forEach(element => {
      if (element.classList.contains("font-bold")) {
        element.classList.remove('font-bold')
      }
    });
    this.relationshipInputTarget.value = ""
    this.documentInputTarget.value = ""
    this.fileInputTarget.value =  null

  }

  closeBackground() {
    if (event.target === this.backgroundModalTarget) { this.closeModal() }
  }
}
