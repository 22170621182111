import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["button", 'input', 'inputTag']

  connect() {
    if ((this.inputTarget.value !== '' || this.inputTagTarget.value !== '' ) && this.buttonTarget.classList.contains('hidden')) {
      this.buttonTarget.classList.remove("hidden");
    }
    if (this.inputTagTarget.value !== '') {
      this.inputTagTarget.classList.remove('text-grey-500')
      this.inputTagTarget.classList.add('text-grey-700')
    }
  }

  change() {
    if ((this.inputTarget.value !== '' || this.inputTagTarget.value !== '') && this.buttonTarget.classList.contains('hidden')) {
      this.buttonTarget.classList.remove("hidden");
    }
  }
}
