import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "input", "inputToggle", 'courseIdInput', 'booleanInput', "availabilities", 'newForm', 'divTop','error']
  static values = { position: String, weekday: Number }

  connect() {
    if (this.inputToggleTarget.dataset.boolean == "true") {
      this.inputToggleTarget.checked = true
    } else {
      this.inputToggleTarget.checked = false
      this.availabilitiesTarget.innerHTML = 'Pas de disponibilité ce jour là';
      this.newFormTarget.style.display = 'none'
      this.divTopTarget.classList.toggle('pt-6')
    }
    this.booleanInputTarget.value = this.inputToggleTarget.dataset.boolean
  }

  submit(event) {
    event.stopPropagation();
    const wkDay = this.weekdayValue;
    const courseId = this.courseIdInputTarget.value;
    const boolean = this.booleanInputTarget.value;
    if (event.target.checked) {
      this.booleanInputTarget.value = true
    } else {
      this.booleanInputTarget.value = false
    }
    fetch(this.formTarget.action, {
      method: 'POST',
      headers: { "Content-Type": "application/json", 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({ availability: { course_id: courseId, week_day: wkDay, is_active: boolean } })
    })
      .then(response => response.json())
      .then((data) => {
        this.errorTarget.textContent = '';
        if (data.inserted_item) {
          this.availabilitiesTarget.innerHTML = '';
          this.newFormTarget.style.display = 'block'
          this.divTopTarget.classList.toggle('pt-6')
          this.availabilitiesTarget.insertAdjacentHTML(this.positionValue, data.inserted_item.split('[')[0]);
        } else  {
          this.availabilitiesTarget.innerHTML = 'Pas de disponibilité ce jour là';
          this.newFormTarget.style.display = 'none'
          this.divTopTarget.classList.toggle('pt-6')
        }
        // event.target.outerHTML = data.form;
      });
  }

  send() {
    event.preventDefault();
    // this.errorTarget.innerHTML = "";
    fetch(event.target.action, {
      method: 'POST',
      headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: new FormData(event.target)
    })
      .then(response => response.json())
      .then((data) => {
        this.errorTarget.textContent = '';
        if (data.inserted_item) {
          if (this.hasNoAvailabilitiesTarget) {
            this.noAvailabilitiesTarget.style.display = 'none';
          }
          if (this.inputToggleTarget.checked === false) {
            this.availabilitiesTarget.textContent = ''
            this.inputToggleTarget.checked = true
            this.inputToggleTarget.dataset.boolean = true
            this.booleanInputTarget.value = this.inputToggleTarget.dataset.boolean
          }
          this.availabilitiesTarget.insertAdjacentHTML(this.positionValue, data.inserted_item);
          document.getElementById("nextButton").disabled = false
        }
        if (data.error) {
          this.errorTarget.insertAdjacentHTML(this.positionValue, data.error);
        }
        // event.target.outerHTML = data.form;
      });
  }
}
