import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pNoUnpaid", "divNoUnpaid", "divBookingsUnpaid", "hBookingsUnpaid", "linkPaiement"]

  connect() {

  }

  toggleBookingsInPayment() {
    if (this.hasPNoUnpaidTarget) {
      this.pNoUnpaidTarget.style.display = "none";
    }
    const bookings = this.divBookingsUnpaidTarget.children
    const target = this.divNoUnpaidTarget;
    var date = new Date()
    target.insertAdjacentHTML('afterBegin',
      `<div class="card-parametre mb-4 ">
        <div class="flex justify-between pt-4  px-4 pb-4 bg-grey-200 rounded-t-lg cursor-pointer" data-action="click->paragraph#toggleCard" >
          <h3 class=" flex-grow cursor-pointer" >Paiement du   ${('0' + date.getDate()).slice(-2) + "/" + ('0' + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()} : ${bookings.length} rendez-vous </h3>
        </div>
        <div class="close text-sm px-4 py-4">`);
    var closeDiv = document.querySelector('.close');
    for (let index = 0; index < bookings.length; index++) {
      closeDiv.insertAdjacentHTML('afterBegin',
        `<p>${bookings[index].innerHTML}</p>`)
      }
    closeDiv.insertAdjacentHTML('beforeEnd',
    `</div>`)
    this.divBookingsUnpaidTarget.innerHTML = `<h3> Vous n'avez pas de rendez-vous non payés en attente avec ce clients.</h3>`;
    this.hBookingsUnpaidTarget.style.display='none';
    this.linkPaiementTarget.style.display='none';
  }
}
