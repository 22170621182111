import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    const toolbar = this.element.previousSibling
    const variableButtonHTML = '<button id="be-placed" type="button" class="trix-button" data-left="258" style="padding-right:8px;padding-left:8px" data-trix-attribute="heading" title="Variable" data-action="click->components--dropdown#dropdownToggle"> { } </button>'
    const once = {
      once: true
    }

    addEventListener("trix-initialize", function (event) {
      const sibling1 = toolbar.querySelector(".trix-button--icon-increase-nesting-level")
      sibling1.insertAdjacentHTML("afterend", variableButtonHTML)
    }, once)
  }

}
