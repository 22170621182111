import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";
import { Callbacks } from "jquery";

export default class extends Controller {
  static targets = ["table", "form", 'title', 'user', 'header', 'cell', 'customCell', 'background', 'dropdownToggle', 'fixedDropdown', "dropdownShown"]

  connect() {

  }


  addcolumn(event) {
    event.preventDefault();
    const title = this.titleTarget.value;
    const user = this.userTarget.value;
    fetch(this.formTarget.action, {
      method: 'POST',
      headers: { "Content-Type": "application/json", 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({ custom_info: { title: title, user_id: user } })
    })
    .then(response => response.json())
    .then((data) => {
      this.headerTarget.insertAdjacentHTML('beforebegin', data.head);
      this.cellTargets.forEach(cell => {
        const cellToAdd = data.cells.filter(function (hash) { return hash.relationship_id === cell.dataset.relationshipId; })
        cell.insertAdjacentHTML('beforebegin', cellToAdd[0].cell)
      });
      this.backgroundTarget.classList.toggle('hidden')
      this.dropdownToggleTarget.classList.toggle('hidden')
      this.titleTarget.value = "";
    });
  }

  deleteCustomInfo() {
    const customElement = event.target
    const custom_info_id = customElement.id
    fetch("/custom_infos/" + custom_info_id, {
      method: 'DELETE',
      headers: { "Content-Type": "application/json", 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({ custom_info: { id: custom_info_id } })
    })
    .then(() => {
      customElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.display="none"
      this.customCellTargets.forEach(cell => {
        if (cell.dataset.customId === custom_info_id) {
          cell.style.display = "none"
        }
      });
    });
  }

  editCustomInfo() {

    document.getElementById("title-" + event.target.dataset.customId).textContent = event.target.previousElementSibling.firstChild.value
  }

  toggleForm() {
    event.target.nextElementSibling.classList.toggle('hidden')
  }

  placeFixedDropdown() {
    const height = parseInt(event.target.dataset.height)
    const left = event.target.getBoundingClientRect().left;
    const top = event.target.getBoundingClientRect().top + height;
    const dropdown = this.fixedDropdownTargets.filter(function (dropdown) { return dropdown.dataset.linkId === event.target.dataset.linkId })
    dropdown[0].style.left = left + 'px';
    dropdown[0].style.top = top + 'px';
  }

  onScrollRelationship() {
    if (this.hasDropdownShownTarget) {
      const height = parseInt(this.dropdownShownTarget.parentElement.parentElement.previousElementSibling.dataset.height)
      const left = this.dropdownShownTarget.parentElement.parentElement.previousElementSibling.getBoundingClientRect().left;
      const top = this.dropdownShownTarget.parentElement.parentElement.previousElementSibling.getBoundingClientRect().top + height;
      this.dropdownShownTarget.parentElement.parentElement.left = left + 'px';
      this.dropdownShownTarget.parentElement.parentElement.style.top = top + 'px';
    }
  }

  onScrollPrestation() {
    const dropdownOpened = this.fixedDropdownTargets.filter(function (dropdown) { return dropdown.classList.contains('hidden') === false })
    if (dropdownOpened.length !== 0) {
      const height = parseInt(dropdownOpened[0].previousElementSibling.previousElementSibling.dataset.height)
      const left = dropdownOpened[0].previousElementSibling.previousElementSibling.getBoundingClientRect().left;
      const top = dropdownOpened[0].previousElementSibling.previousElementSibling.getBoundingClientRect().top + height;
      dropdownOpened[0].style.left = left + 'px';
      dropdownOpened[0].style.top = top + 'px';
    }
  }

}
