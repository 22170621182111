import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", 'button']

  connect() {
  }

  copy() {
    const text = this.sourceTarget.innerHTML || this.sourceTarget.value

    let originalContent = this.buttonTarget.innerHTML
    navigator.clipboard.writeText(text).then(() => this.copied(originalContent))
  }

  copied(originalContent) {
    if (!this.hasButtonTarget) return



    this.buttonTarget.innerText = this.data.get('successContent')

    setTimeout(() => {
      this.buttonTarget.innerHTML = originalContent
    }
      , 2000);
  }
}
