import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["availabilities", "addInput"]
  static values = { position: String }

  connect() {
  }

  nextAvailabilities() {
    const offer_url_token = this.addInputTarget.dataset.offerToken
    const start_date = this.addInputTarget.dataset.startDate
    fetch("/offers/next_availabilities", {
      method: 'POST',
      headers: { "Content-Type": "application/json", 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({ offer_url_token: offer_url_token, start_date: start_date} )
    })
      .then(response => response.json())
      .then((data) => {
        if (data.inserted_item) {
          this.availabilitiesTarget.insertAdjacentHTML(this.positionValue, data.inserted_item);
          this.addInputTarget.dataset.startDate = data.start_date
        }
      });
  }
}
