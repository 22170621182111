import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nextBookingsButton", "passedBookingsButton", "automizeButton", "welcomeButton", "nextBookings", "passedBookings", "automatisation","welcome"]

  connect() {
    this.nextBookingsTarget.hidden = false;
    this.passedBookingsTarget.hidden = true;
    if (this.hasAutomatisationTarget) {
      this.expertTabs(true, true);
    }
  }

  showNextBookings() {
    this.nextBookingsTarget.hidden = false;
    this.passedBookingsTarget.hidden = true;
    if (this.hasAutomatisationTarget) {
      this.expertTabs(true, true);
    }
    this.pinked();

  }

  showPassedBookings() {
    this.nextBookingsTarget.hidden = true;
    this.passedBookingsTarget.hidden = false;
    if (this.hasAutomatisationTarget) {
      this.expertTabs(true, true);
    if (this.hasAutomatisationTarget) {}

    }
    this.pinked();

  }

  showAutomize() {
    this.nextBookingsTarget.hidden = true;
    this.passedBookingsTarget.hidden = true;
    if (this.hasAutomatisationTarget) {
      this.expertTabs(false, true);
    }
    this.pinked();

  }

  showWelcome() {
    this.nextBookingsTarget.hidden = true;
    this.passedBookingsTarget.hidden = true;
    if (this.hasAutomatisationTarget) {
      this.expertTabs(true, false);
    }
    this.pinked();

  }

  pinked() {
    const active_link = document.querySelector(".relationship_navbar > .navbar-link.active");
    if (active_link != event.target){
      event.target.classList.add('active');
      active_link.classList.remove('active');
    }
  }

  expertTabs(automatisation, welcome) {
    this.automatisationTarget.hidden = automatisation;
    this.welcomeTarget.hidden = welcome;
  }

}
