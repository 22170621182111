import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["editButton", 'textArea', 'errorTextSms',
  'successTextSms', 'emptyTextSms', 'successTextMail', 'emptyTextMail',
    'subscribeCard', 'stripeTime', 'unSubscribeButton', 'SubscribeButton',
    "pUrlVerification"
  ]

  connect() {
    if (this.hasStripeTimeTarget && this.stripeTimeTarget.dataset.status == 'active' ) {
      this.stripeTimeTarget.innerText = `Votre accès mensuel sera renouvellé le  ${this.stripeTimeTarget.dataset.time}`
      this.unSubscribeButtonTarget.style.display = 'block';
      this.SubscribeButtonTarget.style.display = 'none';
    } else if (this.hasStripeTimeTarget &&  this.stripeTimeTarget.dataset.status == 'cancelled') {
      this.stripeTimeTarget.innerText = `Vous aurez accès à votre compte jusqu'au ${this.stripeTimeTarget.dataset.time}`
      this.unSubscribeButtonTarget.style.display = 'none';
      this.SubscribeButtonTarget.style.display = 'block';
    }
  }

  editable() {
    this.successTextSmsTarget.style.display = 'none';
    this.emptyTextSmsTarget.style.display = 'block';
    if (this.textAreaTarget.value.includes('{{ time }}') && this.textAreaTarget.value.includes('{{ expert_name }}'))
    {
      this.editButtonTarget.disabled = false;
      this.errorTextSmsTarget.style.display = 'none';
    }
    else
    {
      this.editButtonTarget.disabled = true;
      this.emptyTextSmsTarget.style.display = 'none';
      this.errorTextSmsTarget.style.display = 'block';
    }
  }

  submit() {
    this.editButtonTarget.disabled = false;
    this.errorTextSmsTarget.style.display = 'none';
    this.successTextSmsTarget.style.display = 'block';
    this.emptyTextSmsTarget.style.display = 'none';
  }

  submitMail() {
    this.successTextMailTarget.style.display = 'block';
    this.emptyTextMailTarget.style.display = 'none';
  }

  clickCheckbox() {
    this.successTextMailTarget.style.display = 'none';
    this.emptyTextMailTarget.style.display = 'block';
  }

  changeCard() {
    if (this.stripeTimeTarget.dataset.status == 'active') {
      this.stripeTimeTarget.innerText = `Vous aurez accès à votre compte jusqu'au ${this.stripeTimeTarget.dataset.time}`
      this.unSubscribeButtonTarget.style.display = 'none';
      this.SubscribeButtonTarget.style.display = 'block';

    } else if (this.stripeTimeTarget.dataset.status == 'cancelled') {
      this.stripeTimeTarget.innerText = `Votre accès mensuel sera renouvellé le  ${this.stripeTimeTarget.dataset.time}`
      this.unSubscribeButtonTarget.style.display = 'block';
      this.SubscribeButtonTarget.style.display = 'none';
    }
  }

  urlVerification() {
    if (event.target.value.includes("http")) {
      this.pUrlVerificationTarget.style.color = "#505253"
    } else {
      this.pUrlVerificationTarget.style.color = "red"
    }
  }


}
