import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["availabilities", "noAvailabilities", "error", 'nextButton']
  static values = { position: String }

  connect() {
  }

}

