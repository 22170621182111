import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['plusButton', 'minusButton', 'card', 'title']

  connect() {
    if ( this.hasCardTarget && this.cardTarget.getAttribute("data-boolean") !== null && this.cardTarget.dataset.boolean == "true") {
      this.showCard();
    } else if (this.hasCardTarget && this.cardTarget.getAttribute("data-boolean") !== null && this.cardTarget.dataset.boolean == "false") {
      this.hideCard();
    }
  }

  showCard() {
    this.cardTarget.style.display = "block";
    this.plusButtonTarget.classList.remove("flex")
    this.plusButtonTarget.classList.add("hide")
    this.minusButtonTarget.classList.remove("hide")
    this.minusButtonTarget.classList.add("flex")
  }

  hideCard() {
    this.cardTarget.style.display = "none";
    this.plusButtonTarget.classList.remove("hide")
    this.plusButtonTarget.classList.add("flex")
    this.minusButtonTarget.classList.remove("flex")
    this.minusButtonTarget.classList.add("hide")
  }

  toggleCard(event) {
    if (this.cardTarget.style.display == "none") {
      this.showCard()
      this.titleTarget.classList.add('rounded-t')
      this.titleTarget.classList.remove('rounded')
    } else {
      this.hideCard()
      this.titleTarget.classList.add('rounded')
      this.titleTarget.classList.remove('rounded-t')
    };
  }

}
