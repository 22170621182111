import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import { fetchWithToken } from "../utils/fetch_with_token";
export default class extends Controller {

  static targets = ["calendar", "createEventBusyButton", "createBookingButton", "modal",
                    "start_on", "end_on", "start_on_update", "start_time", "relationship_id", "event_id", "end_time",
                    'destroyBookingButton', 'action', "select_form_client", "header_form", "header_no_click",
                    "relationship_fullname", "relationship_email", "relationship_phone", "relationship_link", "header_form_title_busy", "header_form_title_booking",
                    "background", 'flatpickr', 'startAtOrigin', 'modifyBookingButton', 'cancelButton',
                    'dropdownToggle', 'tickOffer', 'offer', 'offer_id', "select_form_offer", "paragrah_form_offer", 'offer_title', 'course_title', 'course_available_place', 'course_bookers', 'google_title', 'availability_offer_title',
    'header_seance', 'header_session', "header_busy", "destroyBusyButton", 'header_google', 'dropdownTitle', 'dropdownHeader',
    'non_user_first_name', 'non_user_last_name', 'non_user_email', 'non_user_phone', 'create_form_client']

  connect() {

    this.tickOfferTargets.forEach( elt => {
      if (elt.parentElement.dataset.state === 'unactive') {
        elt.style.display ="none"
      } else {
        elt.style.display = "block"
      }
    })

    let _this = this
    this.calendar = new Calendar(this.calendarTarget, {
      locales: [frLocale],
      locale: 'fr',
      allDaySlot: false,
      eventSources: [
        //  les sources :
        //  pour les courses : '/course_sessions.json'
        //  pour les bookings : '/bookings/index_bookings_from_offer_and_availabilities.json'
        //  pour les evt google et busy : '/events.json'
        //  pour les disponibilité des offres: /courses/${event.target.dataset.offerUrlToken}/availabilities_index_by_offer
        {
          id: 1,
          url: '/course_sessions.json',
        },
        {
          id: 2,
          url: '/bookings/index_bookings_from_course_individual_and_availabilities.json',
        },
        {
          id: "google_events",
          url: `/events.json`
        }
        // any other event sources...

      ],
      editable: true,
      eventStartEditable: false,
      selectable: true,
      eventDurationEditable: false,
      eventColor: '#B7F8E1',
      eventTextColor: '#038054',
      initialView: 'timeGridWeek',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      dateClick: function (info) {
        _this.destroyBookingButtonTarget.hidden = true ;
        _this.destroyBusyButtonTarget.hidden = true ;
        _this.modifyBookingButtonTarget.hidden = true ;
        _this.createBookingButtonTarget.hidden = false ;
        _this.createEventBusyButtonTarget.hidden = true;
        _this.select_form_clientTarget.classList.remove("hidden");
        _this.select_form_offerTarget.classList.remove("hidden");
        _this.paragrah_form_offerTarget.classList.remove("hidden");
        _this.start_onTarget.setAttribute("data-start", info.date)
        _this.event_idTarget.setAttribute("data-event-id", "")
        _this.start_onTarget.innerHTML = `<span class="font-bold">De :</span> ${info.date.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h') }`;
        _this.end_onTarget.innerHTML = `<span class="font-bold">Au :</span> A déterminer`;
        _this.modalTarget.setAttribute("data-ouverture", "click");
        _this.modalTarget.style.display = "block";
      },
      select: function (selectionInfo) {
        _this.destroyBookingButtonTarget.hidden = true ;
        _this.destroyBusyButtonTarget.hidden = true ;
        _this.modifyBookingButtonTarget.hidden = true;
        _this.createBookingButtonTarget.hidden = false ;
        _this.createEventBusyButtonTarget.hidden = true;
        _this.select_form_clientTarget.classList.remove("hidden");
        _this.select_form_offerTarget.classList.remove("hidden");
        _this.paragrah_form_offerTarget.classList.remove("hidden");
        _this.start_onTarget.setAttribute("data-start", selectionInfo.start)
        _this.end_onTarget.setAttribute("data-end", selectionInfo.end)
        _this.event_idTarget.setAttribute("data-event-id", "")
        _this.start_onTarget.innerHTML = `<span class="font-bold">De :</span> ${selectionInfo.start.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h')}`;
        _this.end_onTarget.innerHTML = `<span class="font-bold">Au :</span> ${selectionInfo.end.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h')}`
        _this.modalTarget.setAttribute("data-ouverture", "scroll");
        _this.modalTarget.style.display = "block";
      },
      eventClick: function (info) {
        _this.modifyBookingButtonTarget.hidden = true;
        _this.createEventBusyButtonTarget.hidden = true;
        _this.createBookingButtonTarget.hidden = true;
        //  click sur booking
        if (info.event.extendedProps.booked){
          if (info.event.extendedProps.price !== 0) {
            _this.destroyBookingButtonTarget.hidden = true;
          }
          _this.cancelButtonTarget.hidden = true;
          _this.modifyBookingButtonTarget.hidden = false;
          _this.destroyBusyButtonTarget.hidden = true;
          const fp = _this.flatpickrTarget._flatpickr;
          fp.clear();
          _this.flatpickrTarget.nextElementSibling.value = info.event.start.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h');
          _this.startAtOriginTarget.value = info.event.start;
          _this.header_no_clickTarget.hidden = false;
          _this.displayHeader("seance")
          _this.header_formTarget.hidden = true;
          if (info.event.extendedProps.title_offer !== "" ) {
            _this.offer_titleTarget.innerHTML = `<span class="font-bold">Type :</span> ${info.event.extendedProps.title_offer}`;
            _this.offer_titleTarget.hidden = false;
          }
          _this.relationship_fullnameTarget.innerHTML = `<span class="font-bold">Avec :</span> ${info.event.title}`;
          _this.relationship_fullnameTarget.hidden = false;
          _this.relationship_emailTarget.innerHTML = `<span class="font-bold">Email :</span> ${info.event.extendedProps.email}`;
          _this.relationship_emailTarget.hidden = false;
          _this.relationship_phoneTarget.innerHTML = `<span class="font-bold">Téléphone :</span> ${info.event.extendedProps.phone}`;
          _this.relationship_phoneTarget.hidden = false;
          _this.relationship_linkTarget.innerHTML = `<a class="underline text-pink-400" href="/relationships/${info.event.extendedProps.relationship_url}/activity">Son activité</a>`;
          _this.relationship_linkTarget.hidden = false;
          _this.start_on_updateTarget.style.display = "flex";
          _this.start_onTarget.hidden = true;
        }
        //  click sur course session
        if (info.event.extendedProps.type_of_event === 'course_session'){
          _this.destroyBookingButtonTarget.hidden = true;
          _this.destroyBusyButtonTarget.hidden = true;
          _this.cancelButtonTarget.hidden = true;
          if (info.event.extendedProps.title_course !== "") {
            _this.course_titleTarget.innerHTML = `<span class="font-bold">Type :</span> ${info.event.title}`;
            _this.course_available_placeTarget.innerHTML = `<span class="font-bold">Places dispo :</span> ${info.event.extendedProps.available_place}`;
            _this.course_bookersTarget.innerHTML = `<span class="font-bold">Avec :</span> ${info.event.extendedProps.non_users.join()}`;

          }
          _this.course_titleTarget.hidden = false;
          _this.course_available_placeTarget.hidden = false;
          _this.course_bookersTarget.hidden = false;
          _this.startAtOriginTarget.value = info.event.start;
          _this.header_no_clickTarget.hidden = false;
          _this.displayHeader("session")
          _this.header_formTarget.hidden = true;
          _this.start_onTarget.hidden = false;
        }
        //  click sur event busy
        if (info.event.extendedProps.type_of_event === 'event_busy'){
          _this.destroyBookingButtonTarget.hidden = true;
          _this.cancelButtonTarget.hidden = false;
          if (info.event.extendedProps.title_course !== "") {
            _this.course_titleTarget.innerHTML = `<span class="font-bold">Type :</span> ${info.event.title}`;
          }
          _this.course_titleTarget.hidden = false;
          _this.startAtOriginTarget.value = info.event.start;
          _this.header_no_clickTarget.hidden = false;
          _this.displayHeader("busy")
          _this.header_formTarget.hidden = true;
          _this.start_onTarget.hidden = false;
        }
        //  click sur availability
        if (info.event.extendedProps.type_of_event === 'availability'){
          _this.destroyBookingButtonTarget.hidden = true;
          _this.destroyBusyButtonTarget.hidden = true;
          _this.cancelButtonTarget.hidden = false;
          if (info.event.extendedProps.offer_title !== "") {
            _this.availability_offer_titleTarget.innerHTML = `<span class="font-bold">Offre :</span> ${info.event.extendedProps.offer_title}`;
          }
          _this.availability_offer_titleTarget.hidden = false;
          _this.course_titleTarget.hidden = true;
          _this.course_available_placeTarget.hidden = true;
          _this.course_bookersTarget.hidden = true;
          _this.offer_titleTarget.hidden = true;
          _this.startAtOriginTarget.value = info.event.start;
          _this.header_no_clickTarget.hidden = true;
          _this.header_formTarget.hidden = false;
          _this.start_onTarget.hidden = false;
          _this.header_form_title_busyTarget.classList.remove("active");
          _this.header_form_title_bookingTarget.classList.remove("active");
        }
        //  click sur event_google
        if (info.event.extendedProps.type_of_event === 'event_google'){
          _this.destroyBookingButtonTarget.hidden = true;
          _this.destroyBusyButtonTarget.hidden = true;
          _this.cancelButtonTarget.hidden = true;
          _this.google_titleTarget.innerHTML = `<span class="font-bold">Type :</span> ${info.event.title}`;
          _this.google_titleTarget.hidden = false;
          _this.course_titleTarget.hidden = true;
          _this.course_available_placeTarget.hidden = true;
          _this.course_bookersTarget.hidden = true;
          _this.offer_titleTarget.hidden = true
          _this.startAtOriginTarget.value = info.event.start;
          _this.header_no_clickTarget.hidden = false;
          _this.displayHeader("google")
          _this.header_formTarget.hidden = true;
          _this.start_onTarget.hidden = false;
        }
        info.jsEvent.preventDefault;
        _this.start_onTarget.setAttribute("data-start", info.event.start)
        _this.end_onTarget.setAttribute("data-end", info.event.end)
        _this.event_idTarget.setAttribute("data-event-id", info.event.id)
        _this.start_onTarget.innerHTML = `<span class="font-bold">De :</span> ${info.event.start.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h')}`;
        _this.end_onTarget.innerHTML = `<span class="font-bold">Au :</span> ${info.event.end.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h')}`;
        _this.modalTarget.style.display = "block";

      },
    })
    this.calendar.render();
    this.element[this.identifier] = this.calendar;
  }

  displayHeader(str) {
    this.header_seanceTarget.style.display = 'none';
    this.header_sessionTarget.style.display = 'none';
    this.header_busyTarget.style.display = 'none';
    this.header_googleTarget.style.display = 'none';

    if (str === "seance") {
      this.header_seanceTarget.style.display = 'block';
    } else if (str === "session") {
      this.header_sessionTarget.style.display = 'block';
    } else if (str === "busy") {
      this.header_busyTarget.style.display = 'block';
    } else if (str === "google") {
      this.header_googleTarget.style.display = 'block';
    }
  }

  displayBusyForm(){
    this.toggleActiveHeader(event)
    this.actionTarget.setAttribute("value", "busy");
    this.select_form_clientTarget.classList.add("hidden");
    this.select_form_offerTarget.classList.add("hidden");
    this.paragrah_form_offerTarget.classList.add("hidden");
    this.modifyBookingButtonTarget.hidden = true;
    this.createEventBusyButtonTarget.hidden = false;
    this.createBookingButtonTarget.hidden = true;
    this.relationship_idTarget.selectedIndex = 0;
    this.offer_idTarget.selectedIndex = 0;
    if (this.end_onTarget.innerHTML ===  `<span class="font-bold">Au :</span> A déterminer`) {
      this.end_onTarget.innerHTML = `<span class="font-bold">Indisponible sur les 30 minutes</span> `
    }
  }

  displayBookingForm(){
    this.toggleActiveHeader(event)
    this.actionTarget.setAttribute("value", "booking");
    this.select_form_clientTarget.classList.remove("hidden");
    this.select_form_offerTarget.classList.remove("hidden");
    this.paragrah_form_offerTarget.classList.remove("hidden");
    this.modifyBookingButtonTarget.hidden = true;
    this.createEventBusyButtonTarget.hidden = true;
    this.createBookingButtonTarget.hidden = false;
    this.relationship_idTarget.selectedIndex = 0;
    this.offer_idTarget.selectedIndex = 0;
    if (this.end_onTarget.innerHTML === `<span class="font-bold">Indisponible sur les 30 minutes</span> `) {
      this.end_onTarget.innerHTML = `<span class="font-bold">Au :</span> A déterminer`
    }
  }

  toggleActiveHeader(event) {
    this.header_form_title_busyTarget.classList.remove("active");
    this.header_form_title_bookingTarget.classList.remove("active");
    event.target.classList.toggle("active");
  }

  modifyBooking() {
    const start_date = this.flatpickrTarget.value;
    const start_on_origin = this.startAtOriginTarget.value;
    const event_id = this.event_idTarget.getAttribute("data-event-id");
    const url = `/bookings/${event_id}/update_booking_of_course_individual_in_calendar`
    fetchWithToken(url, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ booking: { id: event_id, start_on: start_date, start_on_origin: start_on_origin} })
    })
      .then(response => response.json())
      .then((data) => {
        this.calendar.refetchEvents();
      });
    this.closeModal();
    ;
  }

  openflatpickr() {
    const fp = this.flatpickrTarget._flatpickr;
    fp.open();
  }


  closeModal() {
    this.modalTarget.style.display = "none";
    this.destroyBookingButtonTarget.hidden = false;
    this.destroyBusyButtonTarget.hidden = false;
    this.modifyBookingButtonTarget.hidden = true;
    this.createEventBusyButtonTarget.hidden = false;;
    this.createBookingButtonTarget.hidden = false;
    this.createBookingButtonTarget.disabled = true;
    this.relationship_idTarget.selectedIndex = 0;
    this.offer_idTarget.selectedIndex = 0;
    this.select_form_clientTarget.classList.add("hidden");
    this.select_form_offerTarget.classList.add("hidden");
    this.paragrah_form_offerTarget.classList.add("hidden");
    this.header_no_clickTarget.hidden = true;
    this.header_formTarget.hidden = false;
    this.relationship_fullnameTarget.hidden = true;
    this.offer_titleTarget.hidden = true;
    this.availability_offer_titleTarget.hidden = true;
    this.course_titleTarget.hidden = true;
    this.course_available_placeTarget.hidden = true;
    this.course_bookersTarget.hidden = true;
    this.google_titleTarget.hidden = true;
    this.relationship_emailTarget.hidden = true;
    this.relationship_phoneTarget.hidden = true;
    this.relationship_linkTarget.hidden = true;
    this.start_on_updateTarget.style.display = "none";
    this.start_onTarget.hidden = false;
    this.cancelButtonTarget.hidden = false;

    this.header_form_title_busyTarget.classList.remove("active");
    this.header_form_title_bookingTarget.classList.add("active");

    this.non_user_first_nameTarget.value = ""
    this.non_user_last_nameTarget.value = ""
    this.non_user_phoneTarget.value = ""
    this.non_user_emailTarget.value = ""

    if (!this.create_form_clientTarget.classList.contains("hidden")) {
      this.create_form_clientTarget.classList.add("hidden")
    }
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) { this.closeModal() }
  }

  createEventBusy() {
    const start_date = this.start_onTarget.getAttribute("data-start");
    const end_date = this.end_onTarget.getAttribute("data-end");
    fetchWithToken("/events/create_event_busy_in_calendar", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({ event: { start_on: start_date, end_on: end_date}})
      })
        .then(response => response.json())
        .then((data) => {
          this.calendar.refetchEvents();
        });
    this.closeModal();
  }


  destroyBooking() {
    const event_id = this.event_idTarget.getAttribute("data-event-id");
    const start_date = this.start_onTarget.getAttribute("data-start");
    const end_date = this.end_onTarget.getAttribute("data-end");
    const url = `/bookings/${event_id}/delete_booking_of_course_individual_in_calendar`
    fetchWithToken(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ booking: { id: event_id } })
    })
      .then((data) => {
        this.calendar.refetchEvents();
      });

    this.closeModal();
  }

  destroyBusy() {
    const event_id = this.event_idTarget.getAttribute("data-event-id");
    const url = `/events/${event_id}/delete_event_busy_in_calendar`
    fetchWithToken(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ event: { id: event_id } })
    })
      .then((data) => {
        this.calendar.refetchEvents();
      });

    this.closeModal();
  }

  createBooking() {
    const offer_id = this.offer_idTarget.value.split(',')[0];
    const start_date = this.start_onTarget.getAttribute("data-start");
    const end_date = this.end_onTarget.getAttribute("data-end");
    const relationship_id = this.relationship_idTarget.value;
    if (relationship_id !== ""){
      var createBookingParams = { booking: { relationship_id: relationship_id, start_on: start_date, end_on: end_date, course_id: offer_id  }}
    } else {
      const non_user_first_name = this.non_user_first_nameTarget.value;
      const non_user_last_name = this.non_user_last_nameTarget.value;
      const non_user_email = this.non_user_emailTarget.value;
      const non_user_phone = this.non_user_phoneTarget.value;
      var createBookingParams = { booking: { relationship_id: relationship_id, start_on: start_date, end_on: end_date, course_id: offer_id  },
      non_user: {first_name: non_user_first_name, last_name: non_user_last_name, email: non_user_email, phone: non_user_phone} }
    }


    fetchWithToken("/bookings/create_booking_of_course_individual_in_calendar", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(createBookingParams)
    })
      .then(response => response.json())
      .then((data) => {
        this.calendar.refetchEvents();
      });

    this.closeModal();
  }

  selectClientBooking() {
    this.disabledCreatedButton()
  }

  selectOfferBooking() {
    if (this.modalTarget.dataset.ouverture === "click") {
      let end = new Date(this.start_onTarget.getAttribute("data-start"));
      end.setTime(end.getTime() + parseInt(event.target.value.split(',')[1]) * 60 * 1000)
      this.end_onTarget.innerHTML = `<span class="font-bold">Au :</span> ${end.toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h')}`;
      this.end_onTarget.setAttribute("data-end", end)
    }
    this.disabledCreatedButton()
  }

  selectOfferAvailabilities(event) {
    const offerUrlToken = Array.from(event.target.selectedOptions, option => option.value)[0]

    this.offerTargets.forEach(elt => {
      if( elt.dataset.offerUrlToken !== offerUrlToken) {

        const source = this.calendar.getEventSourceById(elt.dataset.offerUrlToken)
        if (source){
          source.remove()
        }
      }
    })
    if (offerUrlToken !== ""){
      this.calendar.addEventSource({ id: offerUrlToken, url: `/courses/${offerUrlToken}/availabilities_index_by_offer`})
    }
  }

  toggleNonUserForm() {
    this.create_form_clientTarget.classList.toggle('hidden')
    this.relationship_idTarget.value = ''
    this.disabledCreatedButton()
  }

  disabledCreatedButton() {
    if ((this.relationship_idTarget.value != "" && this.offer_idTarget.value != "") || (this.offer_idTarget.value != "" && this.non_user_first_nameTarget.value != "" && this.non_user_last_nameTarget.value != "")) {
      this.createBookingButtonTarget.disabled = false;
    } else {
      this.createBookingButtonTarget.disabled = true;
    }
  }
}
