import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "stimulus";
import { useMutation } from "stimulus-use";

export default class extends Controller {
  connect() {

    useMutation(this, { attributes: true, childList: true, subtree: true });
  }

  disconnect() {
    window.removeEventListener("popstate", this.popStateListener);
  }

  mutate(entries) {
    for (const mutation of entries) {

      if (mutation.type === "childList") {
        // re-initialize third party, e.g. jquery, plugins
      }

      if (mutation.type === "attributes" && mutation.attributeName === "src") {
        history.pushState(
          { turbo_frame_history: true },
          "",
          this.element.getAttribute("src")
        );
      }
    }

    this.popStateListener = event => {
      if (event.state.turbo_frame_history) {
        Turbo.visit(window.location.href, { action: "replace" });
      }
    };

    window.addEventListener("popstate", this.popStateListener);
  }
}
