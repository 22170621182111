import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["errorStripe", "priceInput", 'inputBoolean', 'externUrlInput', 'pUrlVerification', 'pPeopleMax']

  connect() {
    if (this.priceInputTarget.dataset.hasStripe === "false" && this.priceInputTarget.value > 0) {
      document.getElementById("toggle_simpleform_input").checked = false;
      document.getElementById("toggle_simpleform_input").disabled = true;
      this.inputBooleanTarget.value = "archived"
      this.errorStripeTarget.style.display = 'block'
    } else if (this.priceInputTarget.dataset.hasStripe === "false" && this.priceInputTarget.value === "0") {
      this.errorStripeTarget.style.display = 'none'
    } else if (this.priceInputTarget.dataset.hasStripe === "true") {
      this.errorStripeTarget.style.display = 'none'
    }
  }

  change() {
    if (event.target.dataset.hasStripe === "false" && event.target.value > 0) {
      document.getElementById("toggle_simpleform_input").checked = false;
      document.getElementById("toggle_simpleform_input").disabled = true;
      this.inputBooleanTarget.value = "archived"
      this.errorStripeTarget.style.display = 'block'
    } else if (event.target.dataset.hasStripe === "false" && event.target.value === "0") {
      document.getElementById("toggle_simpleform_input").checked = true;
      document.getElementById("toggle_simpleform_input").disabled = false;
      this.inputBooleanTarget.value = "active"
      this.errorStripeTarget.style.display = 'none'
    }
  }

  urlVerification() {
    if (event.target.value.includes("http")) {
      this.pUrlVerificationTarget.style.color = "#505253"
    } else {
      this.pUrlVerificationTarget.style.color = "red"
    }
  }

  changePeopleMax() {
    this.pPeopleMaxTarget.innerHTML = "Le changement ne s'applique qu'aux prochaines disponibilités créées"
  }
}
