import Flatpickr from "stimulus-flatpickr";

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/themes/light.css";

// import the translation files and create a translation mapping
import { French } from "flatpickr/dist/l10n/fr.js";
import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  locales = {
    fr: French,
    en: english
  };



  connect() {
    //define locale and global flatpickr settings for all datepickers

    this.config = {
      locale: document.getElementById('new_presence_sheet').dataset.locale,
      showMonths: 1,
      enableTime: false,
      altInput: true,
      altFormat: "l d F Y "
    };
    super.connect();
  }

  close(selectedDates, dateStr, instance) {
    if (instance.altInput.parentNode.id === "start-form") {
      document.getElementById('presence_sheet_start_on').value = selectedDates[0]
      this.changeTimeInputStart();
    }
    else if (instance.altInput.parentNode.id === "end-form") {
      document.getElementById('presence_sheet_end_on').value = selectedDates[0]
      this.changeTimeInputEnd();
    }
  }


  changeTimeInputStart() {
    const date_in_array = document.getElementById('presence_sheet_start_on').value.split(' ')
    date_in_array[4] = `${document.getElementById('start_at_hour').value}:${document.getElementById('start_at_minute').value}:00`
    document.getElementById('presence_sheet_start_on').value = date_in_array.join(' ')
  }
  changeTimeInputEnd() {
    const date_in_array = document.getElementById('presence_sheet_end_on').value.split(' ')
    date_in_array[4] = `${document.getElementById('end_at_hour').value}:${document.getElementById('end_at_minute').value}:00`
    document.getElementById('presence_sheet_end_on').value = date_in_array.join(' ')
  }
}
