import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sideBar", "contenu"]

  connect() {
  }

  showSideBar() {
    this.sideBarTarget.style.transform = ("");
  }

  hideSideBar() {
    this.sideBarTarget.style.transform = ("translateX(100%) translateZ(0px)");
  }

  fillDocument() {
    this.contenuTarget.classList.remove('border','rounded','border-grey-400','border-dashed','flex','justify-center','items-center','text-grey-400')
    this.contenuTarget.innerHTML = event.target.innerHTML
  }

}
