import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["inputTrue", 'inputFalse', "trueSide", 'falseSide', 'booleanInput', 'pVerificationTrue']

  connect() {
    if (this.booleanInputTarget.value === "true") {
      this.trueSideTarget.checked = true
      this.inputFalseTarget.style.display = 'none'
      if (this.hasPVerificationTrueTarget) {
        this.pVerificationTrueTarget.style.display = 'block'
      }
    } else {
      this.falseSideTarget.checked = true
      this.inputTrueTarget.style.display = 'none'
      if (this.hasPVerificationTrueTarget) {
        this.pVerificationTrueTarget.style.display = 'none'
      }
    }
  }

  switchTwoInput(event) {
    if (event.target.dataset.side === "true") {

      this.booleanInputTarget.value = true
      this.inputFalseTarget.style.display = 'none'
      this.inputTrueTarget.style.display = 'block'
      if (this.hasPVerificationTrueTarget) {
        this.pVerificationTrueTarget.style.display = 'block'
      }

    } else {
      this.booleanInputTarget.value = false
      this.inputTrueTarget.style.display = 'none'
      if (this.hasPVerificationTrueTarget) {
        this.pVerificationTrueTarget.style.display = 'none'
      }
      this.inputFalseTarget.style.display = 'block'
    }
  }

  switchOneInput(event) {
    if (event.target.dataset.side === "true") {

      this.booleanInputTarget.value = true
      this.inputTrueTarget.style.display = 'block'
      if (this.hasPVerificationTrueTarget) {
        this.pVerificationTrueTarget.style.display = 'block'
      }

    } else {
      this.booleanInputTarget.value = false
      this.inputTrueTarget.style.display = 'none'
      if (this.hasPVerificationTrueTarget) {
        this.pVerificationTrueTarget.style.display = 'none'
      }
    }
  }

  // toogleCss(event, sidestring) {
  //   let side
  //   if (sidestring === "true") {
  //     side = this.trueSideTarget
  //   } else if (sidestring === "false") {
  //     side = this.falseSideTarget
  //   }
  //   event.target.classList.remove('bg-white')
  //   event.target.classList.remove('text-primary-blue')
  //   event.target.classList.add('bg-blue-700')
  //   event.target.classList.add('text-white')
  //   side.classList.remove('bg-blue-700')
  //   side.classList.remove('text-white')
  //   side.classList.add('bg-white')
  //   side.classList.add('text-primary-blue')
  // }



}
