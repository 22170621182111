import { Controller } from "stimulus"
import { fetchWithToken } from "../utils/fetch_with_token";

export default class extends Controller {
  static targets = ['cardElement', 'cardErrors', 'form', 'paymentDiv', 'offerName', "offreIndexDiv", "offerPrice", "paymentInput", "successDiv"]

  connect() {
    this.paymentDivTarget.style.display= 'none';
    this.successDivTarget.style.display = "none";
    var stripe = Stripe(this.formTarget.dataset.stripeKey);
    var elements = stripe.elements();
    var style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    var card = elements.create('card', {
      style: style
    });

    card.mount(this.cardElementTarget);
    // Handle real-time validation errors from the card Element.
    let controller = this;
    card.addEventListener('change', function (event) {
      var displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Handle form submission.
    var form = controller.formTarget;
    form.addEventListener('submit', function (event) {
      event.preventDefault();

      stripe.createToken(card).then(function (result) {
        if (result.error) {
          // Inform the user if there was an error.
          if (this.hasCardErrorsTarget) {
            var errorElement = this.cardErrorsTarget;
            errorElement.textContent = result.error.message;
          }
        } else {
          // Send the token to your server.
          controller.submitForm(form, card, stripe, controller);
        }
      });
    });
  }

  choose() {
    const form = this.formTarget;
    fetchWithToken("/payment_intent_offer", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ offer_id: event.target.dataset.id , relationship_id: event.target.dataset.relationshipid  })
    })
      .then(response => response.json())
      .then((data) => {
        form.dataset.secret = data.client_secret

        this.openPaymentDiv(data.offer_name, data.offer_price, data.offer_quantity );
      });
  }


  submitForm(form, card, stripe, controller) {
      var clientSecret = form.dataset.secret
      stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: controller.offerNameTarget.dataset.name,
          }
        }
      }).then(function (result) {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          var errorElement = this.cardErrorsTarget;
          errorElement.textContent = result.error.message;
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {

            controller.openSuccessDiv()

            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
          }
        }
      });
    }

  openPaymentDiv(name, price, quantity) {
    this.offerNameTarget.innerText = `${name}`
    this.offerPriceTarget.innerText = `${price} €`
    this.offreIndexDivTarget.style.display = "none"
    this.paymentDivTarget.style.display = "flex";
    }

  openSuccessDiv() {
    this.paymentInputTarget.style.display = "none";
    this.successDivTarget.style.display = "flex";
  }

}
