import { Controller } from "stimulus"
import Rails from "@rails/ujs";
window.Rails = Rails;


export default class extends Controller {
  static targets = [ "form", "status" ]

  connect() {
    this.timeout  = null
    this.duration = this.data.get("duration") || 1000
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "Enregistrement en cours..."
      Rails.fire(this.formTarget, 'submit')
    }, this.duration)

  }

  success() {
    const date = new Date().toLocaleString("fr-FR").replace(',', ' à').slice(0, -3).replace(':', 'h');
    this.setStatus(`Enregistré le ${date}`)
  }

  error() {
    this.setStatus("Impossible d'enregistrer...")
  }

  setStatus(message) {
    this.statusTarget.textContent = message

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = ""
    }, 10000)
  }
}
