import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["dropdownToggle", "background",
    "editCustomInfo", "trixEditor",
    "autosave"
  ]

  connect() {
  }

  dropdownToggle() {
    if (event.target.id === "campaign-tag" || event.target.id === "be-placed") {
      this.placeDropdown(event)
    }
    this.dropdownToggleTarget.classList.toggle("hidden");
    if (this.hasBackgroundTarget) {
      this.backgroundTarget.classList.toggle("hidden");
    }
    if (this.hasEditCustomInfoTarget && !this.editCustomInfoTarget.classList.contains('hidden')) {
      this.editCustomInfoTarget.classList.add('hidden')
    }
  }

  hiddeToggle() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.element.remove()
  }

  hideToggleCss() {
    this.backgroundTarget.classList.toggle("hidden");
    this.dropdownToggleTarget.classList.toggle("hidden");
  }

  searchTag() {
    if (event.target.parentElement.id === "dropdown-tag") {
      document.getElementById('tag_id').value = event.target.dataset.tagId
      document.getElementById('opener-search-tag').textContent = event.target.textContent
      document.getElementById('opener-search-tag').classList.remove('text-grey-500')
      document.getElementById('opener-search-tag').classList.add('text-grey-700')
    }
    if ((document.getElementById('search').value !== '' || document.getElementById('tag_id').value !== '') && document.getElementById('cross').classList.contains('hidden')) {
      document.getElementById('cross').classList.remove("hidden");
    }
  }

  placeDropdown(event) {
    const height = 40
    let leftToAdd
    if (event.target.dataset.left) {
      leftToAdd = event.target.dataset.left
    }
    else {
      leftToAdd = '0'
    }
    const left = event.target.getBoundingClientRect().left;
    const top = event.target.getBoundingClientRect().top + height;
    const dropdown = this.dropdownToggleTarget
    dropdown.style.left = left - parseInt(leftToAdd) + 'px';
    dropdown.style.top = top + 'px';
  }

  addVariable() {
    const input = this.trixEditorTarget
    input.editor.insertString(`{{ ${event.target.dataset.variable} }}`)
    this.hideToggleCss()

    if (this.hasAutosaveTarget)  {
      const autosave = this.application.getControllerForElementAndIdentifier(this.autosaveTarget, 'autosave')
      autosave.save()
    }

    if (this.hasAutosaveTarget)  {
      const autosave = this.application.getControllerForElementAndIdentifier(this.autosaveTarget, 'autosave')
      autosave.save()
    }

    if (document.getElementById("text-contenu")) {
      document.getElementById("text-contenu").classList.remove('border', 'rounded', 'border-grey-400', 'border-dashed', 'flex', 'justify-center', 'items-center', 'text-grey-400')
      document.getElementById("text-contenu").innerHTML = this.trixEditorTarget.innerHTML
    }
  }

}
