import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["colorInput", 'colors', 'title']

  connect() {
    if (this.hasTitleTarget) {this.titleTarget.classList.add(`bg-${this.colorInputTarget.value}`)}
    const colors_inputs_in_array = Array.from(this.colorsTarget.children)
    for (let div of colors_inputs_in_array) {
      if (div.dataset.color === this.colorInputTarget.value) {
        div.innerHTML = `<i class="fas fa-check fa-md text-white"></i>`;
        break;
      };
    }
  }

  choose() {
    if (this.hasTitleTarget) { this.titleTarget.classList.remove(`bg-${this.colorInputTarget.value}`) }
    if (this.hasTitleTarget) { this.titleTarget.classList.add(`bg-${event.target.dataset.color}`) }
    this.colorInputTarget.value = event.target.dataset.color;
    const colors_inputs_in_array = Array.from(this.colorsTarget.children)
    colors_inputs_in_array.forEach(function(div) {
      div.innerHTML = ``
    });
    event.target.innerHTML = `<i class="fas fa-check fa-md text-white"></i>`
  }
}
