import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "button", 'scrollDiv']


  connect() {
    this.isFinished()
  }

  isFinished() {
    const isRead = this.scrollDivTarget.dataset.isRead
    var objDiv = this.scrollDivTarget;
    if (isRead === "false" && objDiv.scrollHeight === Math.round(objDiv.scrollTop +
      objDiv.clientHeight) ) {
        this.scrollDivTarget.dataset.isRead = "true"
      if (this.hasCheckboxTarget) {
          this.checkboxTarget.disabled = false
          this.checkboxTarget.checked = true
          this.buttonTarget.disabled = false
        }
    }
  }
}
