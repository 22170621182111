import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["successTextSms", "emptyTextSms", "textArea", "editButton","errorTextSms"]

  connect() {
  }

  editable() {
    this.successTextSmsTarget.style.display = 'none';
    this.emptyTextSmsTarget.style.display = 'block';
    if (this.textAreaTarget.value.includes('{{ time }}') && this.textAreaTarget.value.includes('{{ expert_name }}')) {
      this.editButtonTarget.disabled = false;
      this.errorTextSmsTarget.style.display = 'none';
    }
    else {
      this.editButtonTarget.disabled = true;
      this.emptyTextSmsTarget.style.display = 'none';
      this.errorTextSmsTarget.style.display = 'block';
    }
  }

  submit() {
    this.editButtonTarget.disabled = false;
    this.errorTextSmsTarget.style.display = 'none';
    this.successTextSmsTarget.style.display = 'block';
    this.emptyTextSmsTarget.style.display = 'none';
  }

}
