import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cardElement', 'cardErrors', 'form', "successDiv", "paymentInput"]
  static values = { status: String }

  connect() {
    if (this.statusValue === "paid" ) {
      this.paymentInputTarget.style.display = 'none';
    } else {
      this.successDivTarget.style.display = 'none';
    }
    var stripe = Stripe(this.formTarget.dataset.stripeKey);
    var elements = stripe.elements();
    var style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    var card = elements.create('card', {
      style: style
    });

    card.mount(this.cardElementTarget);
    // Handle real-time validation errors from the card Element.
    let controller = this;
    card.addEventListener('change', function (event) {
      var displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Handle form submission.
    var form = controller.formTarget;
    form.addEventListener('submit', function (event) {
      event.preventDefault();

      stripe.createToken(card).then(function (result) {
        if (result.error) {
          // Inform the user if there was an error.
          if (this.hasCardErrorsTarget) {
            var errorElement = this.cardErrorsTarget;
            errorElement.textContent = result.error.message;
          }
        } else {
          // Send the token to your server.
          controller.submitForm(form, card, stripe, controller);
        }
      });
    });
  }

  submitForm(form, card, stripe, controller) {
    var clientSecret = form.dataset.secret
    stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          // name: controller.offerNameTarget.dataset.name,
        }
      }
    }).then(function (result) {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        var errorElement = controller.cardErrorsTarget;
        errorElement.textContent = result.error.message;
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          controller.openSuccessDiv()
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    });
  }

  openSuccessDiv() {
    this.paymentInputTarget.style.display = "none";
    this.successDivTarget.style.display = "flex";
  }
}
